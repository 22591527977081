import React from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import {
  FaFacebookF,
  // FaTwitter,
  FaLinkedinIn,
  // FaReact,
  FaMedium,
  // FaTerraform,
  FaGithub,
} from "react-icons/fa";

import {
  // SiTailwindcss,
  // SiFigma,
  // SiNextdotjs,
  SiPython,
  SiJenkins,
  SiDocker,
  SiTerraform,
} from "react-icons/si";

const LeftBanner = () => {
  const [text] = useTypewriter({
    words: ["Professional Coder.", "Cloud Engineer.", "AWS Expert"],
    loop: true,
    typeSpeed: 20,
    deleteSpeed: 10,
    delaySpeed: 2000,
  });
  const handleIconClick = (url) => {
    window.open(url, "_blank");
  };
  return (
    <div className="w-full lgl:w-1/2 flex flex-col gap-20">
      <div className="flex flex-col gap-5">
        <h4 className=" text-lg font-normal">WELCOME TO MY WORLD</h4>
        <h1 className="text-6xl font-bold text-white">
          Hi, I'm <span className="text-designColor capitalize">Momodou.</span>
        </h1>
        <h2 className="text-4xl font-bold text-white">
          a <span>{text}</span>
          <Cursor
            cursorBlinking="false"
            cursorStyle="|"
            cursorColor="#ff014f"
          />
        </h2>
        <p className="text-base font-bodyFont leading-6 tracking-wide">
          As a Cloud Engineer, I leverage automation as a critical component to
          streamline and enhance operations, ensuring seamless and efficient
          workflows. My approach is not just about implementing automation for
          efficiency's sake, but about integrating it strategically to improve
          system reliability and performance. This method allows me to guide
          teams through every deployment and maintenance task, enhancing clarity
          and engagement in cloud infrastructure management.
        </p>
      </div>
      <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            Find me on
          </h2>
          <div className="flex gap-4">
            <span
              className="bannerIcon"
              onClick={() =>
                handleIconClick(
                  "https://www.facebook.com/modoulamin.ceesay.50/"
                )
              }
            >
              <FaFacebookF />
            </span>
            <span
              className="bannerIcon"
              onClick={() =>
                handleIconClick(
                  "https://www.linkedin.com/in/momodou-ceesay-788058193/"
                )
              }
            >
              <FaLinkedinIn />
            </span>
            <span
              className="bannerIcon"
              onClick={() => handleIconClick("https://medium.com/@ceesay.ml")}
            >
              <FaMedium />
            </span>
            <span
              className="bannerIcon"
              onClick={() => handleIconClick("https://github.com/cmomodo")}
            >
              <FaGithub />
            </span>
          </div>
        </div>
        <div>
          <h2 className="text-base uppercase font-titleFont mb-4">
            BEST SKILL IN
          </h2>
          <div className="flex gap-4">
            <span className="bannerIcon">
              <SiTerraform />
            </span>
            <span className="bannerIcon">
              <SiPython />
            </span>
            <span className="bannerIcon">
              <SiJenkins />
            </span>
            <span className="bannerIcon">
              <SiDocker />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftBanner;
