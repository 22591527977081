import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Experience = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            2022 - Present
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Security Officer"
            subTitle="G4S - (2023 - Present)"
            result="UK"
            des="As an Area Security Officer, my primary responsibility entails providing coverage across the Warwickshire region. I am typically stationed at the DWPS, where my duties involve ensuring the safety of customers. This involves guiding them to their designated work coaches and maintaining a secure environment for staff members."
          />
          <ResumeCard
            title="Security Officer"
            subTitle="Octavian Security - (September 2022 - June 2024)"
            result="UK"
            des="As a Security Guard, I am dedicated to assisting individuals and identifying issues within the building. My responsibilities include reporting any problems promptly to ensure a safe and secure environment for everyone."
          />
          <ResumeCard
            title="Operations Specialist"
            subTitle="Amazon - (Jan 2019 - October 2022)"
            result="UK"
            des="As an Operations Specialist responsible for the Amazon Departure System, I am fully accountable for the day-to-day operations. I manage a team of 10 employees, oversee the development of new processes, and ensure smooth and efficient operations."
          />
        </div>
      </div>
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            2016 - Present
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Other Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Warehouse Associate"
            subTitle="Amazon (2018 - 2019)"
            result="England"
            des="Working in a fast-paced environment, I learned to manage my time effectively and work well under pressure. I also developed strong communication skills and the ability to work well in a team."
          />
          <ResumeCard
            title="Warehouse Associate"
            subTitle="APC Overnight (2016 - 2018)"
            result="England"
            des="I was responsible for picking and packing orders, loading and unloading vehicles, and maintaining a clean and organized warehouse. I also learned how to use a variety of warehouse equipment and software."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Experience;
