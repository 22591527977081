import React from "react";
import Title from "../layouts/Title";
import {
  bastianHost,
  projectTwo,
  githubActions,
  firefly,
} from "../../assets/index";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <section
      id="projects"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <div className="flex justify-center items-center text-center">
        <Title
          title="VISIT MY PORTFOLIO AND KEEP YOUR FEEDBACK"
          des="Projects"
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-14">
        <ProjectsCard
          title="Bastion Host Server"
          des="This bastion host was created using cloudformation. The default AWS IAC tool. It is a simple server that can be used to host a website or a simple API."
          src={bastianHost}
          githubLink="https://github.com/cmomodo/cloud_bootcamp27/tree/main/cea_cloudformation"
        />
        <ProjectsCard
          title="Parallel & Distributed Programming"
          des="We implemented a parallel and distributed programming project using MPI and OpenMP. The project was to implement a Weather Climate Model."
          src={projectTwo}
          githubLink="https://github.com/cmomodo/Distributed-Parallel-"
        />
        <ProjectsCard
          title="CI/CD Pipeline"
          des="This CI/CD pipeline was created using Github Actions and Terraform. The pipeline is used to build, test, and deploy a simple website."
          src={githubActions}
          githubLink="https://github.com/cmomodo/s3_website_actions.git"
        />
        <ProjectsCard
          title="Load Balancer"
          des="This load balancer was created using Terraform. The load balancer is used to distribute incoming network traffic across multiple servers."
          src={firefly}
          githubLink="https://github.com/cmomodo/ansible"
        />
      </div>
    </section>
  );
};

export default Projects;
