import React from "react";
// import { AiFillAppstore } from "react-icons/ai";
import { FaMobile, FaGlobe } from "react-icons/fa";
import { LuContainer } from "react-icons/lu";
import { SiProgress, SiAntdesign } from "react-icons/si";
import Title from "../layouts/Title";
import Card from "./Card";

const Features = () => {
  return (
    <section
      id="features"
      className="w-full py-20 border-b-[1px] border-b-black"
    >
      <Title title="Features" des="What I Do" />
      <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20">
        <Card
          title="Cloud Optimisation"
          des="Optimising cloud resources to reduce costs and improve performance."
        />
        {/* <Card
          title="System Design"
          // des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque soluta
          // hic consequuntur eum repellendus ad."
          // icon={<AiFillAppstore />}
        /> */}
        <Card
          title="CI/CD Pipelines"
          des="Integrating CI/CD pipelines into the development process to automate the building, testing, and deployment of applications."
          icon={<SiProgress />}
        />
        <Card
          title="Infrastructure as code"
          des="Automating the provisioning of infrastructure using code. This allows for the creation of repeatable and consistent environments."
          icon={<FaMobile />}
        />
        <Card
          title="Bash Scripts"
          des="Using bash scripts to automate repetitive tasks and improve productivity."
          icon={<SiAntdesign />}
        />
        <Card
          title="Containerisation"
          des="Containerising applications making it easier for development and deployment."
          icon={<LuContainer />}
        />
        <Card
          title="Cloud Migration"
          des="Migration of applications to the cloud to improve scalability and reliability."
          icon={<FaGlobe />}
        />
      </div>
    </section>
  );
};

export default Features;
