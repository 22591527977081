import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2016 - 2024</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title=" Cloud Engineering Academy"
            subTitle="Learning About The Cloud (2020-2023)"
            result="Distinction"
            des="Yaml · Python (Programming Language) · Linux · Terraform"
          />
          <ResumeCard
            title=" Conventry University"
            subTitle="BS in Computer Science (2020-2023)"
            result="2.2"
            des="JavaScript · Python (Programming Language) · Linux · React.js"
          />
          <ResumeCard
            title="AWS re/Start program"
            subTitle=" Jun 2022 - Sep 2022 "
            result="Pass"
            des="An online AWS BootCamp. Activities and societies: Learned about Python, Linux, SQL and AWSActivities"
          />
          <ResumeCard
            title="Coventry College"
            subTitle="September 2019 - May 2020"
            result="Merit"
            des="Did my Foundation for my University Degree. Learned About SQL, Python, Linux and JavaScript."
          />
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">
            2016 - Present
          </p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Junior Support Cloud Engineer"
            subTitle="Cloud Engineering Academy - (2024 - Present)"
            result="UK"
            des="As a Junior Support Cloud Engineer, I provide technical support to customers using cloud-based services. I troubleshoot and resolve issues, answer questions, and help customers make the most of their cloud services."
          />
          <ResumeCard
            title="Junior Web Developer"
            subTitle="Pandemic Prevention Network - (Jan 2022 - April 2022)"
            result="UK"
            des="While studying at university, I joined a startup to gain industry experience. After a few months, I decided to leave to prioritize my academic commitments."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
